<span
  class="ox-icon ox-icon--check ox-icon-small align-text-top mr-1"
  [class]="iconColorClass"
></span>

<a
  [href]="transportUrl"
  [class]="cargoColorClass"
  [ngClass]="cargo | highlight"
  target="_blank" rel="noopener noreferrer" class="ox-link">{{ cargo }}</a>

<span [ngClass]="purposeBadgeClass" class="ml-1">{{ purposeBadgeLabel }}</span>

@if (actionPoint.hasDriverNote) {
  <span
    class="ox-icon ox-icon-small ox-icon--comment ml-1"></span>
}

<br/>
