<span class="ox-icon ox-icon--time ox-icon-small align-text-top mr-1"></span>

@if (shift.startedAt) {
  <small>
    {{ 'locale.hours_and_minutes' | translate: totalWorkTime }}
  </small>
} @else {
  <small>
    {{ 'model.dsbMain.NOT_STARTED'|translate }}
  </small>
}
