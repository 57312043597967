<div
  aria-expanded="false"
  aria-haspopup="true"
  class="ox-filter__item ox-dropmenu-wrapper ox-dropmenu-wrapper--filter"
>
  <input
    #filterToggle
    [id]="this.id"
    class="ox-dropmenu-toggle"
    type="checkbox"
  />
  <label
    [class.ox-filter__trigger--active]="this.triggerActive"
    [for]="this.id"
    [id]="this.id + '-label'"
    class="ox-filter__trigger"
    role="button"
  >
    {{ this.label | translate }}{{ this.labelInput }}
  </label>
  <div class="ox-dropmenu ox-dropmenu--left ox-dropmenu--filter p-0">
    <div class="ox-dropmenu__body p-2 pt-3">
      <div class="dropmenu_wrapper">
        @if (this.selectOptions?.length === 0) {
          <small>
            {{ this.noOptionsFoundLabel | translate }}
          </small>
        } @if (this.selectOptions?.length > 0) {
        <div
          class="ox-form__group ox-form__input-group ox-form__input-group--small ox-form__input-group--prepend"
        >
          <input
            [formControl]="this.filterInput"
            class="ox-form__field ox-form__field--small"
            type="text"
            autocomplete="off"
            [id]="'multi-select-input' + this.id"
          />
          <label
            class="ox-form__input-group__unit"
            [for]="'multi-select-input' + this.id"
          >
            <span class="ox-icon ox-icon--search"></span>
          </label>
        </div>
      } @if (this.selectOptionsSubject | async; as visualSelectOptions) {
        <div
          class="ox-form__filterable-dropdown__list__content"
        >
          @for (option of visualSelectOptions; track multiSelectId($index, option)) {
            <div class="ox-form__checkbox ox-form__checkbox--ghost">
              <input
                [(ngModel)]="option.isSelected"
                class="ox-form__checkbox__input"
                id="{{ option.id }}"
                type="checkbox"
                value="{{ option.id }}"
              />
              <label
                class="ox-form__checkbox__label m-0 px-2"
                for="{{ option.id }}">
                {{ option.displayName || (this.nullOptionLabel | translate) }}
              </label>
            </div>
          }
        </div>
      }
      </div>
    </div>
    @if (this.selectOptions?.length) {
      <div class="ox-dropmenu__footer">
        <button
          (click)="clearFilter()"
          class="ox-button ox-button--plain ox-button--small js-apply-clear"
          type="button"
        >
          {{ "model.filter.CLEAR_FILTER" | translate }}
        </button>
        <button
          (click)="applyFilter()"
          class="ox-button ox-button--secondary ox-button--small js-apply-filter"
          type="button"
        >
          {{ "model.filter.APPLY_FILTER" | translate }}
        </button>
      </div>
    }
  </div>
</div>
