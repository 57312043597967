import {Component, Input} from '@angular/core';
import {DeliveryStatus} from '../../../models/delivery-status.model';

@Component({
  selector: 'app-dsb-main-issues',
  templateUrl: './dsb-main-issues.component.html',
  styleUrls: ['./dsb-main-issues.component.scss'],
  standalone: false
})
export class DsbMainIssuesComponent {

  @Input() record: DeliveryStatus;

  get nbUnresolvedIssues() {
    return this.record.nbUnresolvedIssues;
  }
}
