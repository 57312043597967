import {Component, Input} from '@angular/core';
import {Status} from '../../../models/status.model';

@Component({
  selector: 'app-dsb-main-next-depot',
  templateUrl: './dsb-main-next-depot.component.html',
  styleUrls: ['./dsb-main-next-depot.component.scss'],
  standalone: false
})
export class DsbMainNextDepotComponent {

  @Input() status: Status;

}
