<div class="container-fluid">
  <app-dsb-header [records]="filteredAndSortedRecords"></app-dsb-header>

  @if (isLoading == true) {
    <div class="row">
      <div aria-label="Content loading" aria-live="polite" class="col-24 py-5 ox-is-loading" role="status">
        <img alt="Loading..." aria-hidden="true" class="ox-loader"
             src="https://ox.qone.mateco.eu/assets/img/loader.svg"/>
      </div>
    </div>
  }

  @if (!isLoading && filteredAndSortedRecords.length == 0) {
    <div class="row mt-2">
      <div class="ox-notification ox-notification--warning col-24">
        <span class="ox-notification__icon ox-icon ox-icon--danger"></span>
        <div class="ox-notification__content">
          <h4 class="ox-notification__title">{{ 'model.dsbGrid.NO_PLANNED'|translate }}</h4>
        </div>
      </div>
    </div>
  }

  @if (!isLoading) {
    @for (record of (filteredAndSortedRecords); track record.driver.driverId) {
      <div class="font-size-90">
        <app-dsb-main-row [record]="record"></app-dsb-main-row>
        <app-dsb-detail-row [record]="record"></app-dsb-detail-row>
      </div>
    }
  }
</div>
