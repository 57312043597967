import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Locales} from 'src/app/models/enums/Locales';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
  standalone: false
})
export class LanguageSwitchComponent implements OnInit, OnDestroy {

  public control = new UntypedFormControl();

  private readonly onDestroy$ = new Subject<void>();
  private readonly defaultLang = Locales.EN_US;

  constructor(private translateService: TranslateService) {
  }

  get availableLanguages() {
    return Object.values(Locales);
  }

  ngOnInit() {
    this.translateService.setDefaultLang(this.defaultLang);
    const lang = window.localStorage.getItem('language') || this.defaultLang;
    this.translateService.use(lang);
    this.control.setValue(lang);

    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((language) => {
      this.translateService.use(language);
      window.localStorage.setItem('language', language);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

