import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeliveryStatus} from 'src/app/models/delivery-status.model';
import {BroadcastingService} from '../../services/broadcasting.service';
import {Subscription} from 'rxjs';
import {Action} from '../../events/row-status';

@Component({
  selector: 'app-dsb-detail-row',
  templateUrl: './dsb-detail-row.component.html',
  styleUrls: ['./dsb-detail-row.component.scss'],
  standalone: false
})
export class DsbDetailRowComponent implements OnInit, OnDestroy {

  @Input() public record: DeliveryStatus;

  isCollapsed: boolean = true;

  private _subscription: Subscription;

  constructor(private broadcastingService: BroadcastingService) {
  }

  ngOnInit(): void {
    this._subscription = this.broadcastingService.onRowStatusChanged.subscribe((event) => {
      if (event.action === Action.EXPAND_ALL) {
        this.isCollapsed = false;
      } else if (event.action === Action.COLLAPSE_ALL) {
        this.isCollapsed = true;
      } else if (event.driverId === this.record.driver.driverId) {
        this.isCollapsed = event.action === Action.COLLAPSE;
      }
    });
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

}
