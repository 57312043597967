import {Component, Input} from '@angular/core';
import {UnloadCargoIssue} from '../../../models/unload-cargo-issue.model';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-issues-detail',
  templateUrl: './issues-detail.component.html',
  styleUrls: ['./issues-detail.component.scss'],
  standalone: false
})
export class IssuesDetailComponent {

  @Input() issues: UnloadCargoIssue[];

  createdAt(issue: UnloadCargoIssue) {
    return issue.createdAt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
  }

  resolvedAt(issue: UnloadCargoIssue) {
    return issue.resolvedAt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
  }

}
