@if (opcos) {
  <select (change)="onOpcoChanged($event.target.value)"
          [formControl]="control"
          aria-label="Select opco"
          class="ox-form__field ox-form__field--small">
    @for (opco of opcos; track opco) {
      <option [value]="opco">
        {{ opco }}
      </option>
    }
  </select>
} @else {
  <div aria-label="Content loading"
       aria-live="polite"
       class="ox-is-loading mr-3"
       role="status">
    <img alt="Loading..."
         aria-hidden="true"
         class="ox-loader ox-loader--small"
         src="https://ox.qone.mateco.eu/assets/img/loader.svg"/>
  </div>
}
