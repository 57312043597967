import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TransportStatus} from 'src/app/models/transport-status.model';

@Component({
  selector: 'app-dsb-main-transport-progress',
  templateUrl: './dsb-main-transport-progress.component.html',
  styleUrls: ['./dsb-main-transport-progress.component.scss'],
  standalone: false
})
export class DsbMainTransportProgressComponent implements OnChanges {

  @Input() progress: TransportStatus;

  finishedDeliveries: number;
  totalDeliveries: number;
  finishedPickups: number;
  totalPickups: number;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.progress) {
      if (this.progress) {
        this.finishedDeliveries = this.progress.finishedDeliveries;
        this.totalDeliveries = this.progress.totalDeliveries;
        this.finishedPickups = this.progress.finishedPickups;
        this.totalPickups = this.progress.totalPickups;
      }
    }
  }
}
