import {Component, Input} from '@angular/core';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {AlphabeticalSortingStrategy} from '../../sortable-header/sorting-strategies/alphabetical-sorting-strategy';
import {TimeSortingStrategy} from '../../sortable-header/sorting-strategies/time-sorting-strategy';
import {TourSortingStrategy} from '../../sortable-header/sorting-strategies/tour-sorting-strategy';
import {TransportStatusSortingStrategy} from '../../sortable-header/sorting-strategies/transport-status-sorting-strategy';
import {GeneralStatusSortingStrategy} from '../../sortable-header/sorting-strategies/general-status-sorting-strategy';
import {DateUtilService} from '../../services/date-util.service';
import {NumericSortingStrategy} from '../../sortable-header/sorting-strategies/numeric-sorting-strategy';

@Component({
  selector: 'app-dsb-header',
  templateUrl: './dsb-header.component.html',
  styleUrls: ['./dsb-header.component.scss'],
  standalone: false
})
export class DsbHeaderComponent {
  @Input() public records: DeliveryStatus[];

  constructor(private readonly dateUtilService: DateUtilService) {
  }

  get sortByDriverName() {
    return new AlphabeticalSortingStrategy((record) => record.driver.accountName);
  }

  get sortByUnresolvedIssueCount() {
    return new NumericSortingStrategy((record) => record.nbUnresolvedIssues);
  }

  get sortByNextDepot() {
    if (this.records.every(record => !record.status.nextDepot)) {
      return null;
    }
    return new AlphabeticalSortingStrategy((record) => record.status.nextDepot);
  }

  get sortByTourId() {
    return new TourSortingStrategy(this.dateUtilService, (record) => record.tours);
  }

  get sortByWorkingTime() {
    if (this.records.every(record => !record.shift.totalWorkTime)) {
      return null;
    }
    return new TimeSortingStrategy((record) => record.shift.totalWorkTime);
  }

  get sortByTransportStatus() {
    return new TransportStatusSortingStrategy();
  }

  get sortByGeneralStatus() {
    return new GeneralStatusSortingStrategy();
  }
}
