import {Component} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {AuthenticationService} from "../services/authentication.service";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: false
})
export class ToolbarComponent {

  user$ = this.authService.user$;

  constructor(private readonly authService: AuthService,
              private readonly authenticationService: AuthenticationService) {
  }

  logout() {
    this.authenticationService.logout();
  }
}
