import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SortingService} from '../services/sorting.service';
import {SortingOrder} from '../models/enums/SortingOrder';
import {SortingStrategy} from './sorting-strategies/sorting-strategy';

@Component({
  selector: 'app-sortable-header',
  templateUrl: './sortable-header.component.html',
  styleUrls: ['./sortable-header.component.scss'],
  standalone: false
})
export class SortableHeaderComponent implements OnChanges, OnInit {
  @Input() public title: string;
  @Input() public sortingStrategy: SortingStrategy;
  private _sortingOrder: SortingOrder = SortingOrder.DISABLED;

  constructor(private readonly sortingService: SortingService) {
  }

  // ngOnInit because we are working with Input fields
  ngOnInit() {
    this.sortingService.activeStrategyAndOrder.subscribe(activeStrategyAndOrder => {
      if (this.sortingStrategy?.equals(activeStrategyAndOrder.sortingStrategy)) {
        // reset header's state if it isn't currently sorted
        this._sortingOrder = activeStrategyAndOrder.sortingOrder;
      } else {
        this._sortingOrder = SortingOrder.DISABLED;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.sortingStrategy?.currentValue && // Strategy became null (a way to disable the component).
      this._sortingOrder !== SortingOrder.DISABLED) { // Previous strategy was in use and active.
      this._sortingOrder = SortingOrder.DISABLED;
      this.sortingService.defaultSorting();
    }
  }

  switchState() {
    if (this.sortingStrategy) {
      switch (this._sortingOrder) {
        case SortingOrder.DESCENDING:
        case SortingOrder.DISABLED:
          this.sortingService.setSorting(this.sortingStrategy, SortingOrder.ASCENDING);
          break;
        case SortingOrder.ASCENDING:
          this.sortingService.setSorting(this.sortingStrategy, SortingOrder.DESCENDING);
          break;
      }
    }
  }

  getSortableHeaderClass() {
    let assignedClass = '';
    if (this.sortingStrategy) {
      assignedClass += 'cursor-pointer ';
    }
    switch (this._sortingOrder) {
      case SortingOrder.DISABLED:
        assignedClass += 'ox-sortable-header__trigger';
        break;
      case SortingOrder.ASCENDING:
        assignedClass += 'ox-sortable-header__trigger ox-sortable-header__trigger--sort-asc';
        break;
      case SortingOrder.DESCENDING:
        assignedClass += 'ox-sortable-header__trigger ox-sortable-header__trigger--sort-desc';
        break;
    }
    return assignedClass;
  }

  get sortingOrder() {
    // added for testing
    return this._sortingOrder;
  }
}
