@for (tour of enhancedTours; track tour.externalTourId; let isFirstTour = $first; let isLastTour = $last) {
  <div
     [ngClass]="{
       'pb-2' : !isLastTour,
       'pt-2' : !isFirstTour,
       'horizontal-line' : !isLastTour
     }"
    class="row">
    <div class="col-sm-4">
      <strong [ngClass]="tour.externalTourId | highlight" class="text-dark mr-2">
        <a target="_blank" rel="noopener noreferrer" class="ox-link"
          [href]="tourUrl(tour.externalTourId)">
        {{tour.externalTourId}}</a>
      </strong>
      @if (tour.isUnfinished) {
        <small>({{'model.dsbDetail.UNFINISHED'|translate}}
        , {{ tour.departureDate }})</small>
      }
      @if (tour.isUpcoming) {
        <small>({{'model.dsbDetail.UPCOMING'|translate}}
        , {{ tour.departureDate }})</small>
      }
    </div>
    <div class="col-sm-15">
      @for (stop of tour.stops; track stop.plannedTimeOfArrival; let isFirstStop = $first; let isLastStop = $last) {
        <div
         [ngClass]="{
           'light': allActionPointsFinishedAtDifferentDate(stop),
           'indented-horizontal-line': !isLastStop
         }"
          class="row">
      <div [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }"
            class="col-sm-2">
            <small [ngClass]="{'font-italic': tour.isPlanned && !tour.isStarted}">
              {{ tour.isStarted ? stop.estimatedTimeOfArrival : stop.plannedTimeOfArrival }}
            </small>
          </div>
          <div [class]="'col-sm-2 text-center ' + verticalLineClass(isFirstStop, isLastStop)"
           [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }">
            <i [ngClass]="tourStopIcon(stop.stopStatus)"
            class="grey-20 align-middle hide-vertical-line"></i>
          </div>
      <div [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }"
            class="col-sm-12">
            <small [ngClass]="stop.name | highlight">{{stop.name}}</small>
          </div>
      <div [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }"
            class="col-sm-4 pl-4">
            @for (actionPoint of stop.actionPoints; track actionPoint.objectId) {
              @if (actionPoint.transportType == 'DELIVERY') {
                <small
                  [ngClass]="{'light': isSingleActionPointFinishedAtDifferentDate(actionPoint, stop)}">
                  <app-transport [actionPoint]="actionPoint"></app-transport>
                </small>
              }
            }
          </div>
      <div [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }"
            class="col-sm-4 pl-4">
            @for (actionPoint of stop.actionPoints; track actionPoint.objectId) {
              @if (actionPoint.transportType == 'PICKUP' || actionPoint.transportType == 'TRANSFER') {
                <small
                  [ngClass]="{'light': isSingleActionPointFinishedAtDifferentDate(actionPoint, stop)}">
                  <app-transport [actionPoint]="actionPoint"></app-transport>
                </small>
              }
            }
          </div>
        </div>
      }
    </div>
    <div class="col-sm-5">
      <app-issues-detail [issues]="tour.issues"></app-issues-detail>
    </div>
  </div>
}
