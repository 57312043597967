@if (status === ApplicationStatus.OFFLINE) {
  <div class="fixed-top text-center mt-2">
    <small class="ox-badge ox-badge--error ox-badge--has-border px-2 py-1">
      <span class="ox-icon ox-icon--danger mr-2"></span>
      <span class="application-status">{{ 'model.header.APPLICATION_IS_OFFLINE' | translate }}</span>
    </small>
  </div>
}

@if (status === ApplicationStatus.UPGRADE_AVAILABLE) {
  <div class="fixed-top text-center mt-2">
    <small class="ox-badge ox-badge--warning ox-badge--has-border px-2 py-1">
      <span class="ox-icon ox-icon--danger mr-2"></span>
      <span class="application-status">{{ 'model.header.APPLICATION_WILL_UPGRADE_SHORTLY' | translate }}</span>
    </small>
  </div>
}

