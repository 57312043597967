import {Component} from "@angular/core";
import {AuthenticationService} from "../services/authentication.service";
import {BroadcastingService} from "../services/broadcasting.service";

@Component({
  selector: "app-unauthorized-handler",
  templateUrl: "./unauthorized-handler.component.html",
  standalone: false
})
export class UnauthorizedHandlerComponent {

  show$ = this.broadcastingService.onUnauthorized;

  constructor(private readonly broadcastingService: BroadcastingService,
              private readonly authenticationService: AuthenticationService) {
  }

  logout() {
    this.authenticationService.logout();
  }
}
