import {Component, OnDestroy, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {PollingDeliveryStatusService} from '../../services/polling-delivery-status-service.service';
import {DepotFilter} from './depot-filter';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {SelectOption} from '../template/multi-select-filter/select-option';
import {MultiSelectFilterComponent} from '../template/multi-select-filter/multi-select-filter.component';
import {takeUntil} from 'rxjs/operators';
import {MultiSelectFilter} from '../multi-select-filter';

@Component({
  selector: 'app-depot-filter',
  templateUrl: './depot-filter.component.html',
  standalone: false
})
export class DepotFilterComponent implements OnDestroy {
  public depotOptions: SelectOption[] = [];

  @ViewChild(MultiSelectFilterComponent)
  public multiSelectFilterComponent: MultiSelectFilterComponent;
  public depotFilter: MultiSelectFilter = new DepotFilter();

  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private readonly deliveryStatusService: PollingDeliveryStatusService
  ) {
    this.deliveryStatusService.records
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((deliveryStatuses: DeliveryStatus[]) => {
        const uniqueDepotNames = Array.from(
          new Set(
            deliveryStatuses.map(
              (deliveryStatus: DeliveryStatus) =>
                deliveryStatus.driver.responsibleDepot
            )
          )
        );
        this.depotOptions = uniqueDepotNames.map((uniqueDepotName: string) => {
          const previousDepotOption = this.depotOptions.find(
            (oldOption) => oldOption.displayName === uniqueDepotName
          );
          return {
            id: uniqueDepotName,
            displayName: uniqueDepotName,
            isSelected: previousDepotOption && previousDepotOption.isSelected,
          };
        });
        this.depotOptions.sort((a1, a2) =>
          a1.displayName > a2.displayName ? 1 : -1
        );
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearFilter(): void {
    this.multiSelectFilterComponent.clearFilter();
  }

  clearDepotOptions(depotOptions: SelectOption[]): void {
    this.depotOptions = depotOptions;
  }
}
