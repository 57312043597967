import {Component, OnDestroy, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {PollingDeliveryStatusService} from '../../services/polling-delivery-status-service.service';
import {DriverFilter} from './driver-filter';
import {SelectOption} from '../template/multi-select-filter/select-option';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {takeUntil} from 'rxjs/operators';
import {MultiSelectFilterComponent} from '../template/multi-select-filter/multi-select-filter.component';
import {MultiSelectFilter} from '../multi-select-filter';

@Component({
  selector: 'app-driver-filter',
  templateUrl: './driver-filter.component.html',
  standalone: false
})
export class DriverFilterComponent implements OnDestroy {
  public driversOptions: SelectOption[] = [];

  @ViewChild(MultiSelectFilterComponent) public multiSelectFilterComponent: MultiSelectFilterComponent;
  public driverFilter: MultiSelectFilter = new DriverFilter();

  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private readonly deliveryStatusService: PollingDeliveryStatusService
  ) {
    this.deliveryStatusService.records
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((deliveryStatuses: DeliveryStatus[]) => {
        this.driversOptions = deliveryStatuses.map((deliveryStatus) => {
          const driverId = deliveryStatus.driver.driverId;
          const oldDriverOption = this.driversOptions.find(
            (oldOption) => oldOption.id === driverId
          );
          return {
            id: driverId,
            displayName: deliveryStatus.driver.accountName,
            isSelected: oldDriverOption && oldDriverOption.isSelected,
          };
        });
        this.driversOptions.sort((a1, a2) =>
          a1.displayName > a2.displayName ? 1 : -1
        );
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearFilter(): void {
    this.multiSelectFilterComponent.clearFilter();
  }

  clearDriverOptions(driverOptions: SelectOption[]) {
    this.driversOptions = driverOptions;
  }
}
