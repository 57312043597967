import {Injectable, OnDestroy} from '@angular/core';
import {DeliveryStatusService} from './delivery-status.service';
import {BehaviorSubject, retry, Subscription, timer} from 'rxjs';
import {DeliveryStatus} from '../models/delivery-status.model';
import {BroadcastingService} from './broadcasting.service';
import {ZonedDate} from '../models/ZonedDate';
import {switchMap} from 'rxjs/operators';
import {ApplicationStatus} from '../models/application-status';
import {RxjsUtil} from '../util/rxjs-util';
import {HttpResponse, HttpStatusCode} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PollingDeliveryStatusService implements OnDestroy {

  private _opco: string;

  constructor(private readonly dsbService: DeliveryStatusService,
              private readonly broadcastingService: BroadcastingService) {
    this.onOpcoCodeChangedSubscription = broadcastingService.onOpcoCodeChanged.subscribe((opco) => {
      this._opco = opco;
      this.restartPolling();
    });
    this.onDateChangedSubscription = broadcastingService.onDateChanged.subscribe((date) => {
      this._date = date;
      this.restartPolling();
    });
  }

  private _subscriptions: Subscription[] = [];

  private readonly onOpcoCodeChangedSubscription: Subscription;
  private readonly onDateChangedSubscription: Subscription;

  // TODO: Investigate if we can refactor this to broadcast via BroadcastingService instead.
  public readonly isLoading = new BehaviorSubject<boolean>(true);
  public readonly records = new BehaviorSubject<DeliveryStatus[]>([]);

  private _date: ZonedDate;

  get date() {
    return this._date;
  }

  ngOnDestroy(): void {
    this.cancelPolling();
    this.onOpcoCodeChangedSubscription.unsubscribe();
    this.onDateChangedSubscription.unsubscribe();
  }

  private restartPolling() {
    if (this._opco && this._date) {
      this.isLoading.next(true);
      this.cancelPolling();
      this.startPolling();
    }
  }

  private cancelPolling() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  /**
   * Start polling the backend for {@link DeliveryStatus} updates.
   * In case of errors (4xx/5xx), the request is retried and the
   * application is considered offline.
   */
  startPolling() {
    this._subscriptions.push(timer(0, 10000).pipe(
        switchMap((time) => this.dsbService.getDeliveryStatus$(this._opco, this._date)),
        retry({
          delay: (error, retryCount) => {
            this.broadcastingService.onStatusChanged.next(ApplicationStatus.OFFLINE);
            if (error instanceof HttpResponse && HttpStatusCode.Forbidden === error.status) {
              this.cancelPolling();
            }
            return RxjsUtil.retryWithExponentialBackoff(retryCount, 10000, 30000);
          }
        })
      )
      .subscribe({
        next: (res) => {
          this.records.next(res);
          this.isLoading.next(false);
          this.broadcastingService.onStatusChanged.next(ApplicationStatus.ONLINE);
        },
        error: (error) => {
          this.broadcastingService.onStatusChanged.next(ApplicationStatus.OFFLINE);
          console.error(`Fatal error when polling delivery statuses: ${error}.`);
        }
      })
    );
  }
}
