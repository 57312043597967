<ul class="list-unstyled">
  @for (issue of issues; track issue) {
    <li class="mb-2">
      @if (issue.resolvedAt) {
        <span class="text-success ox-has-tooltip"
              [attr.data-tooltip]="'model.issues.resolvedAt' | translate: {dateTime: resolvedAt(issue)}">
          <span class="ox-icon ox-icon-small ox-icon--check align-text-top mr-1"></span>
          <span>{{ 'model.issues.unloadCargoIssueResolved' | translate: issue }}</span>
        </span>
      } @else {
        <span class="text-danger ox-has-tooltip"
              [attr.data-tooltip]="'model.issues.createdAt' | translate: {dateTime: createdAt(issue)}">
          <span class="ox-icon ox-icon-small ox-icon--danger align-text-top mr-1"></span>
          <span>{{ 'model.issues.unloadCargoIssueOpen' | translate: issue }}</span>
        </span>
      }
    </li>
  }
</ul>
