import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EnvironmentService} from '../../../../services/environment.service';
import {ActionPoint} from '../../../../models/actionpoint.model';
import {TransportPurpose} from '../../../../models/enums/TransportPurpose';
import {DateUtilService} from '../../../../services/date-util.service';
import {FinishedBy} from '../../../../models/enums/FinishedBy';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss'],
  standalone: false
})
export class TransportComponent implements OnChanges {

  @Input() actionPoint: ActionPoint;

  cargo: string;
  cargoColorClass: string;
  iconColorClass: string;
  transportUrl: string;
  purposeBadgeClass: string;
  purposeBadgeLabel: string;

  constructor(
    private readonly dateService: DateUtilService,
    private readonly environmentService: EnvironmentService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cargo = this.actionPoint.objectId || this.actionPoint.cargoDescription;
    this.cargoColorClass = this.getCargoColorClass();
    this.iconColorClass = this.getIconColorClass();
    this.transportUrl = this.environmentService.qplanningTransportUrl(this.actionPoint.transportId);
    this.purposeBadgeClass = this.getPurposeBadgeClass(this.actionPoint.purpose);
    this.purposeBadgeLabel = this.getPurposeBadgeLabel(this.actionPoint.purpose);
  }

  getIconColorClass() {
    switch (this.actionPoint.finishedBy) {
      case FinishedBy.DRIVER:
      case FinishedBy.PLANNER:
        return this.getCargoColorClass();
      case FinishedBy.NOT_FINISHED:
        return 'invisible';
    }
  }

  getCargoColorClass() {
    switch (this.actionPoint.finishedBy) {
      case FinishedBy.DRIVER:
        return 'text-success';
      case FinishedBy.PLANNER:
        return 'text-primary';
      default:
        return '';
    }
  }

  getPurposeBadgeClass(actionPointPurpose: TransportPurpose) {
    switch (actionPointPurpose) {
      case TransportPurpose.SUBRENTAL:
        return 'ox-badge ox-badge--warning';
      case TransportPurpose.CUSTOMER:
        return 'ox-badge ox-badge--update';
      case TransportPurpose.STOCK_ROTATION:
        return 'ox-badge';
      default:
        return 'd-none';
    }
  }

  getPurposeBadgeLabel(actionPointPurpose: TransportPurpose) {
    switch (actionPointPurpose) {
      case TransportPurpose.SUBRENTAL:
        return 'SUB';
      case TransportPurpose.CUSTOMER:
        return 'C';
      case TransportPurpose.STOCK_ROTATION:
        return 'T';
      default:
        return '';
    }
  }

}
