<div class="d-flex general-status">
  <div>
    <span class="ox-badge ox-badge--success ox-badge--has-border mt-1">
      {{ 'model.filter.DELIVERIES' | translate }}
    </span>
  </div>
  <span class="ox-icon ox-icon--truck-unload ox-icon-large mt-2 ml-2 mr-2"></span>
  <div class="d-flex flex-column">
    <progress aria-valuemin="0" class="m-0 mt-1 mr-3 ox-progress deliveries" max="{{totalDeliveries}}"
              role="progressbar"
              value="{{finishedDeliveries}}"></progress>
    @if (!isLoading) {
      <small>{{ 'model.filter.DELIVERIESBAR'|translate:translationValuesForDeliveries }}</small>
    }
  </div>

  <div>
    <span class="ox-badge ox-badge--has-border mt-1">
      {{ 'model.filter.PICKUPS' | translate }}
    </span>
  </div>
  <span class="ox-icon ox-icon--truck-load ox-icon-large mt-2 ml-2 mr-2"></span>
  <div class="d-flex flex-column">
    <progress aria-valuemin="0" class="m-0 mt-1 mr-5 ox-progress" max="{{totalPickups}}"
              role="progressbar"
              value="{{finishedPickups}}"></progress>
    @if (!isLoading) {
      <small>{{ 'model.filter.PICKUPSBAR'|translate:translationValuesForPickups }}</small>
    }
  </div>
</div>
