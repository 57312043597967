import {Component, Input} from '@angular/core';
import {GeneralStatus, Status} from 'src/app/models/status.model';
import {Tour} from '../../../models/tour.model';
import {FinishedBy} from '../../../models/enums/FinishedBy';

@Component({
  selector: 'app-dsb-main-status',
  templateUrl: './dsb-main-status.component.html',
  styleUrls: ['./dsb-main-status.component.scss'],
  standalone: false
})
export class DsbMainStatusComponent {

  @Input() status: Status;
  @Input() tours: Tour[];

  statusLabel(status: GeneralStatus) {
    switch (status) {
      case GeneralStatus.NOT_STARTED:
        return 'model.status.NOT_STARTED';
      case GeneralStatus.IN_TRANSIT:
        return 'model.status.IN_TRANSIT';
      case GeneralStatus.HANDLING:
        return 'model.status.HANDLING';
      case GeneralStatus.FINISHED:
        return 'model.status.FINISHED';
      case GeneralStatus.FREE:
        return 'model.status.FREE';
    }
  }

  statusBadgeClass(status: GeneralStatus) {
    switch (status) {
      case GeneralStatus.NOT_STARTED:
        return 'text-body';
      case GeneralStatus.IN_TRANSIT:
        return 'ox-badge--update';
      case GeneralStatus.HANDLING:
        return 'text-body';
      case GeneralStatus.FINISHED:
        if (this.allStopsFinishedByPlanner()) {
          return 'ox-badge--success ox-badge--compact py-0 text-primary';
        } else {
          return 'ox-badge--success ox-badge--compact py-0 finished';
        }
      case GeneralStatus.FREE:
        return 'ox-badge--success';
    }
  }

  private allStopsFinishedByPlanner(): boolean {
    return this.tours.every(tour => {
      return tour.stops.every(stop => {
        return stop.actionPoints.every(actionPoint => actionPoint.finishedBy === FinishedBy.PLANNER);
      });
    });
  }

}
